<div class="page-wrapper" [ngClass]="{'toggleClose':!showMenu}" [ngClass]="{'toggleOpen':showMenu}">
<router-outlet>
    <!-- <div class="loading" *ngIf="showLoader">Loading&#8230;</div> -->
    <!-- <div id="loading" *ngIf="showLoader">
        <div id="loading-center">
            <div id="loading-center-absolute">
                <div class="object" id="object_one"></div>
                <div class="object" id="object_two"></div>
                <div class="object" id="object_three"></div>
                <div class="object" id="object_four"></div>
            </div>
        </div>
    </div> -->
</router-outlet>
</div>
<ngx-guided-tour></ngx-guided-tour>

