<div class="information_box">
    <h2>COOKIE POLICY</h2>
    <h4>This Cookie Policy applies to the following Websites:</h4>
    <p><a href="https://app.skyvuesolutions.com">https://www.skyvuesolutions.com</a>,<a href="https://app.skyvuesolutions.com">https://app.skyvuesolutions.com</a> “Websites”)</p>
    <p>This policy summarizes the different types of cookies we use on the Websites, together with their respective purpose and duration (i.e. how long each cookie will remain on your device) and how to prevent and/or delete cookies.</p>
    <p>Except for cookies necessary for the functioning of the Websites, we will place cookies only if you have allowed us to do so by pressing the “Agree” button in the Cookie Information on the top-page of our Websites. Please note that if you do not allow us to place cookies or disable the cookies that we use, this may impact your user experience while on the Websites. If you want to change your settings at any time, you can return to the top-page of our Websites and use the “allow” or “do not allow” buttons. You may also disable cookies by using your browser settings, please be referred to “How to prevent and/or delete cookies” below.</p>
    <h4>What are cookies?</h4>
    <p>Cookies are files or pieces of information that may be stored on your computer (or other internet enabled devices, such as a smartphone or tablet) when you visit a website. A cookie will usually contain the name of the website from which the cookie originated, the “lifetime” of the cookie (i.e. how long it will remain on your device), and a value, which is usually a randomly generated unique number.</p>
    <h4>What we use cookies for</h4>
    <p>The Websites use strictly necessary cookies which are essential to make the Websites work correctly and are strictly necessary in order to enable you to move around the Websites and use its features.</p>
    <p>The Websites also uses “analytical” cookies. They allow us to optimize the performance of the Websites in order to enhance your website experience and to make the Websites easier to use and to better tailor the Websites to your interests and needs. The cookies also enable the user to remain logged in to e.g. the subscription service on the Websites. Further, the cookies help speed up your future activities and experience on the Websites by remembering user preferences such as language and font size and user agent properties, e.g. screen resolution and connection speed. We also use the cookies to compile anonymous, aggregated statistics that allow us to understand how people use the Websites and to help us improve their structure and content.</p>
    <p>Read more about the individual cookies we use and how to recognize them in the table under “Cookies used on the Websites” below.</p>
    <h4>Cookies used on the Websites</h4>
    <h3>There are three types of cookies:</h3>
    <ul>
        <li>persistent cookies</li>
        <li>session cookies and</li>
        <li>third-party cookies</li>
    </ul>
    <p>Persistent cookies save a file for an extended period on your computer and are used, for example, with functions which describe what information is new since you last visited the particular website (how long the cookie remains on your device will depend on the duration or “lifetime” of the specific cookie and your browser settings).</p>
    <p>Session cookies are stored temporarily in your computer’s memory while you are visiting the website and surfing a page and is used, for example, to determine what language you have chosen. Once you leave the website, the session cookie is deleted.</p>
    <h4>Detailed information on the cookies used on the Websites</h4>
    <h4>How to prevent and/or delete cookies</h4>
    <p>Most internet browsers are initially set up to automatically accept cookies. You can change the settings to block cookies or to alert you when cookies are being sent to your device. There are a number of ways to manage cookies. Please refer to your browser instructions or help screen to learn more about how to adjust or modify your browser settings.</p>
    <p>If you disable the cookies that we use, this may impact your experience while on the Websites, for example, you may not be able to visit certain areas of a Skyvue Solutions Site or you may not receive personalized information when you visit a Skyvue Solutions Site.</p>
    <p>If you use different devices to view and access the Websites (e.g. your computer, smartphone, tablet etc.) you will need to ensure that each browser on each device is adjusted to suit your cookie preferences.</p>
    <h4>General</h4>
    <p>Skyvue Solutions LLC may amend this Cookie Policy from time to time.</p>
    <p>Skyvue Solutions LLC</p>
    <p>2nd floor Optic Laser Building, AKWA</p>
    <p>Douala, CAMEROON</p>
    <p>This Cookie Policy was last updated on September 28, 2020.</p>
</div>