import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { TermsAndConditionComponent } from './components/terms-privacy-cookie/terms-and-condition/terms-and-condition.component';
import { PrivacyPolicyComponent } from './components/terms-privacy-cookie/privacy-policy/privacy-policy.component';
import { CookieComponent } from './components/terms-privacy-cookie/cookie/cookie.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'signin',
    // canActivate: [AuthGuard],
    // canLoad: [AuthGuard],
    loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./components/signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./components/forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordModule)
  },
  {
    path: 'termsAndCondition',
    component: TermsAndConditionComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'cookie',
    component: CookieComponent
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    loadChildren: () => import('./components/dashboard/dashboard.component.module').then(m => m.DashboardModule)
  },
  {
    path: 'subscribe',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    loadChildren: () => import('./components/subscribe/subscribe.component.module').then(m => m.SubscribeModule)
  },
  {
    path: 'companies',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    loadChildren: () => import('./components/companies/companies.component.module').then(m => m.CompaniesModule)
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    loadChildren: () => import('./components/notifications/notifications.component.module').then(m => m.NotificationsdModule)
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    loadChildren: () => import('./components/users/users.component.module').then(m => m.UsersModule)
  },
  {
    path: 'projects',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./components/projects/projects.component.module').then(m => m.ProjectModule)
  },
  {
    path: '**',
    loadChildren: () => import('./components/page-no-found/page-no-found.module').then(m => m.PageNotFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
