import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {NgxEncryptCookieService} from 'ngx-encrypt-cookie';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class HelperService {
  key = environment.key ;
  constructor(private cookie:NgxEncryptCookieService,) { }

  sortArrayByKey(array: any, key: any) {
    array = array.sort(function (a: any, b: any) {
      return a[key] - b[key];
    });
    return array;
  }

  validateEmail(email: any) {
    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(email)) {
      return true;
    }
    return false;
  }
  validateUrl(url: any) {
    if (/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/.test(url)) {
      return true;
    } else if(!url){
      return true;
    }
    return false;
  }


  validatePhone(phone: any) {
    if(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(phone)){
      return true;
    }
    return false;
  }

  isNullOrUndefined<T>(tObj: T): boolean {
    return tObj === null || tObj === undefined;
  }

  isStringEmptyOrWhitespace(stringToParse: string) {
    return this.isNullOrUndefined(stringToParse) || stringToParse.trim() === '';
  }

  isArrayEmpty<T>(tArr: T[]): boolean {
    return this.isNullOrUndefined(tArr) || tArr.length <= 0;
  }

  removeAllWhiteSpaces = (text: string) => {
    return text.replace(/\s/g, '');
  }
  replaceNbsps(str: string) {
    var re = new RegExp(String.fromCharCode(160), "g");
    return str.replace(re, " ");
  }
  snakeCaseToLowerCase = (text: string) => {
    return text.replace(/\_/g, '');
  }

  getJWTToken() {
    return localStorage.getItem('token') || null;
  }

  isClientLogin() {
    return !this.isNullOrUndefined(this.cookie.get("type",true,this.key)) && this.cookie.get("type",true,this.key) == "client";
  }

  isImageFile(file) {
    let ext = file.name.split('.').pop().toLowerCase();
    let extList = ['jpg', 'png', 'jpeg', 'bmp', 'svg', 'tiff', 'psd', 'dwg'];
    if (!extList.includes(ext)) {
      return false;
    }
    return true;
  }

  isVideoFile(file) {
    let ext = file.name.split('.').pop().toLowerCase();
    let extList = ['mp4', 'webm'];
    if (!extList.includes(ext)) {
      return false;
    }
    return true;
  }



  isDocumentFile(file) {
    let ext = file.name.split('.').pop().toLowerCase();
    let extList = ['txt', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'tiff', 'psd','jpg','jpeg','png'];
    if (!extList.includes(ext)) {
      return false;
    }
    return true;
  }


  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  checkRoles(value, userDetails, projectId) {
    let companyId = this.cookie.get("companyId",true,this.key);
    switch (value) {
      case 'addUser': {
        if (userDetails.type == 'admin') {

          if (userDetails.role == 'admin') return true;
          else if (userDetails.role == 'collaborator') return false;
          else if (userDetails.role == '') return false;

        } else if (userDetails.type == 'client') return false;
      };
      case 'editDelUser': {
        if (userDetails.type == 'admin') {

          if (userDetails.role == 'admin') return true;
          else if (userDetails.role == 'collaborator') return false;
          else if (userDetails.role == '') return false;

        } else if (userDetails.type == 'client') return false;
      };
      case 'addCompany': {
        if (userDetails.type == 'admin') {

          if (userDetails.role == 'admin') return true;
          else if (userDetails.role == 'collaborator') return false;
          else if (userDetails.role == '') return false;

        } else if (userDetails.type == 'client') return false;
      };
      case 'editDelCompany': {
        if (userDetails.type == 'admin') {

          if (userDetails.role == 'admin') return true;
          else if (userDetails.role == 'collaborator') return false;
          else if (userDetails.role == '') return false;

        } else if (userDetails.type == 'client') return false;
      };
      case 'manageEmployees': {
        if (userDetails.type == 'admin') {

          if (userDetails.role == 'admin') return true;
          else if (userDetails.role == 'collaborator') return false;
          else if (userDetails.role == '') return false;

        } else if (userDetails.type == 'client') {
          //check if user is in any company
          if (!userDetails.companies || userDetails.companies.length == 0) return false;

          let company = userDetails.companies.find((d: any) => d._id == companyId)?.users.find((d: any) => d.user == userDetails._id);
          if (company?.role == 'admin') return true;
          else if (company?.role == 'collaborator') return false;
          else if (company?.role == '') return false;
          else return false;
        };
      };
      case 'createProject': {
        if (userDetails.type == 'admin') {

          if (userDetails.role == 'admin') return true;
          else if (userDetails.role == 'collaborator') return false;
          else if (userDetails.role == '') return false;

        } else if (userDetails.type == 'client') { 
          //check if user is in any company
          if (!userDetails.companies || userDetails.companies.length == 0) return false;
          let company = userDetails.companies.find((d: any) => d._id == companyId)?.users.find((d: any) => d.user == userDetails._id);
          if (company?.role == 'admin') return true;
          else if (company?.role == 'collaborator') return false;
          else if (company?.role == '') return false;
          else return false;
        }
      };
      case 'manageProject': { 
        if (userDetails.type == 'admin') {

          if (userDetails.role == 'admin') return true;
          else if (userDetails.role == 'collaborator') return false;
          else if (userDetails.role == '') return false;

        } else if (userDetails.type == 'client') { 
          //check if user is in any company
          if (!userDetails.companies || userDetails.companies.length == 0) return false;
          let project = userDetails.projects.find((d: any) => d._id == projectId)?.users.find((d: any) => d.user == userDetails._id);
          console.log(userDetails);
          console.log(projectId);
          if (!project) return false;
          else if (project?.role == 'admin') return true;
          else if (project?.role == 'collaborator') return false;
          else if (project?.role == '') return false;
          else return false;
        }
      };
      case 'manageProjectEmployees': {
        if (userDetails.type == 'admin') {

          if (userDetails.role == 'admin') return true;
          else if (userDetails.role == 'collaborator') return false;
          else if (userDetails.role == '') return false;

        } else if (userDetails.type == 'client') {
          //check if user is in any company
          if (!userDetails.companies || userDetails.companies.length == 0) return false;
          let project = userDetails.projects.find((d: any) => d._id == projectId)?.users.find((d: any) => d.user == userDetails._id);
          
          if (!project) return false;
          else if (project?.role == 'admin') return true;
          else if (project?.role == 'collaborator') return false;
          else if (project?.role == 'reportProgress') return false;
          else if (project?.role == 'viewer') return false;
          else if (project?.role == '') return false;
          else return false;
        }
      };
      case 'addFiles': {
        if (userDetails.type == 'admin') {

          if (userDetails.role == 'admin') return true;
          else if (userDetails.role == 'collaborator') return false;
          else if (userDetails.role == '') return false;

        } else if (userDetails.type == 'client') {
          let project = userDetails.projects.find((d: any) => d._id == projectId)?.users.find((d: any) => d.user == userDetails._id);
          // if (project?.role == 'admin' && project.isManageProjects) return true;
          // else if (project?.role == 'admin' && !project.isManageProjects) return false;
          if (project?.role == 'admin') return true;
          else if (project?.role == 'collaborator') return false;
          else if (project?.role == 'reportProgress') return false;
          else if (project?.role == 'viewer') return false;
          else if (project?.role == '') return false;
          else return false;
        }
      };
      case 'addTags': {
        if (userDetails.type == 'admin') {

          if (userDetails.role == 'admin') return true;
          else if (userDetails.role == 'collaborator') return false;
          else if (userDetails.role == '') return false;

        } else if (userDetails.type == 'client') {

          let project = userDetails.projects.find((d: any) => d._id == projectId)?.users.find((d: any) => d.user == userDetails._id);

          if (project?.role == 'admin') return true;
          else if (project?.role == 'collaborator') return false;
          else if (project?.role == 'reportProgress') return true;
          else if (project?.role == '') return false;
          else return false;
        }
      };
      case 'addDatas': {  
        if (userDetails.type == 'admin') {

          if (userDetails.role == 'admin') return true;
          else if (userDetails.role == 'collaborator') return false;
          else if (userDetails.role == '') return false;

        } else if (userDetails.type == 'client') { 
          //check if user is in any company
          if (!userDetails.companies || userDetails.companies.length == 0) return false;
          let project = userDetails.projects.find((d: any) => d._id == projectId)?.users.find((d: any) => d.user == userDetails._id);
          if (!project) return false;
          else if (project?.role == 'admin') return true;
          else if (project?.role == 'collaborator') return true;
          else if (project?.role == '') return false;
          else return false;
        }
      };
      case 'deleteDatas': { 
        if (userDetails.type == 'admin') {

          if (userDetails.role == 'admin') return true;
          else if (userDetails.role == 'collaborator') return false;
          else if (userDetails.role == '') return false;

        } else if (userDetails.type == 'client') { 
          //check if user is in any company
          if (!userDetails.companies || userDetails.companies.length == 0) return false;
          let project = userDetails.projects.find((d: any) => d._id == projectId)?.users.find((d: any) => d.user == userDetails._id);
          if (!project) return false;
          else if (project?.role == 'admin') return true;
          else if (project?.role == 'collaborator') return false;
          else if (project?.role == '') return false;
          else return false;
        }
      };
    }
  }

}
