<!-- <div class="modal-body p-0"> -->
  <div class="add-comp-blk row m-0" @bounce  *ngIf="slide==1" >
      <div class="add-comp-lt col-md-6 p-0">
          <div class=" illustration" >
              <img src="/assets/images/project_tracking.jpg" style="width: 80%;" alt="Placeholder" />
          </div>
      </div>
      <div class="add-comp-rt col-md-6 cd-item-info">
          <h2  style="color: #344563">Welcome!</h2>
          <p>Discover a new way to track your projects wherever you are.
          Get a comprehensive view of your construction site and track update progress.</p>
        <div class="tour-buttons mt-4">
            <button class="skip-button link-button ng-star-inserted" (click)="onCancel()"> Skip </button>
            <button class="next-button ng-star-inserted" (click)="next()"> Next &nbsp;{{slide}}/3</button>
        </div>
      </div>
  <!-- <a (click)="onCancel()" class="cd-close"> -->
    <img src="/assets/images/close_cross.svg" (click)="onCancel()" class="cd-close">
  <!-- </a> -->
  </div>
<!-- </div> -->
<!-- <div class="modal-body p-0"> -->
  <div class="add-comp-blk row m-0"  @bounce  *ngIf="slide==2">
    <div class="add-comp-lt col-md-6 p-0">
      <div class=" illustration">
        <img src="/assets/images/task_mng.jpg" style="width: 80%;" alt="Placeholder" />
      </div>
    </div>
    <div class="add-comp-rt col-md-6 cd-item-info">
      <h2  style="color: #344563">Project tracking</h2>
      <p>We document every stage of construction projects to give access to unprecedented efficient work progress information
      available from everywhere in one click.</p>
      <div class="tour-buttons mt-4">
        <button class="skip-button link-button ng-star-inserted" (click)="onCancel()"> Skip </button>
        <button class="next-button ng-star-inserted" (click)="next()"> Next &nbsp;{{slide}}/3</button>
        <button class="back-button link-button ng-star-inserted" (click)="previous()"> Back </button>
      </div>
      <!-- <ul class="cd-item-action mt-4 p-0 text-right">
                    <li class="float-left"><a (click)="next()"> << Previous </a></li>
                    <li><a (click)="skip()">Skip</a></li>
                    <li class="m-0"><button class="add-to-cart" (click)="next()">Next {{slide}}/3</button></li>
                </ul> -->
    </div>
    <img src="/assets/images/close_cross.svg" (click)="onCancel()" class="cd-close">
  </div>
<!-- </div> -->
  <!-- <div class="modal-body p-0" @bounce  *ngIf="slide==3"> -->
  <div class="add-comp-blk row m-0" @bounce  *ngIf="slide==3">
    <div class="add-comp-lt col-md-6 p-0">
      <div class=" illustration"
        >
        <img src="/assets/images/raw_estimate.png" style="width: 80%;" alt="Placeholder" />
      </div>
    </div>
    <div class="add-comp-rt col-md-6 cd-item-info">
      <h2  style="color: #344563">Material estimation</h2>
      <p>Using our intelligent platform, we are able to accurate determine the amount of raw materials that has gone into any
      building at any given point in time.</p>
      <div class="tour-buttons mt-4">
        <button class="skip-button link-button ng-star-inserted" (click)="onCancel()"> Skip </button>
        <button class="next-button ng-star-inserted" (click)="onCancel()">Get started</button>
        <button class="back-button link-button ng-star-inserted" (click)="previous()" *ngIf="slide>0"> Back </button>
      </div>
      <!-- <ul class="cd-item-action mt-4 p-0 text-right">
                    <li class="float-left"><a (click)="next()"> << Previous </a></li>
                    <li><a (click)="skip()">Skip</a></li>
                    <li class="m-0"><button class="add-to-cart" (click)="next()">Next {{slide}}/3</button></li>
                </ul> -->
    </div>
    <img src="/assets/images/close_cross.svg" (click)="onCancel()" class="cd-close">
  </div>
  <!-- </div> -->
