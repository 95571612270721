<div class="information_box">
    <h2>TERMS AND CONDITIONS</h2>

    <h4>SITE</h4>
    
    <p>These terms and conditions of use (the &ldquo;Terms and Conditions&rdquo;) apply to the Skyvue Solutions LLC (&ldquo;Skyvue Solutions&rdquo;) web site located at www.skyvuesolutions.com, and all associated sites linked to www.skyvuesolutions.com by Skyvue Solutions, its subsidiaries and affiliates (collectively the &ldquo;Web Site&rdquo;).</p>
    
    <h4>AGREEMENT TO TERMS AND CONDITIONS</h4>
    
    <p>These Terms and Conditions constitute a contractual agreement between you and Skyvue Solutions regarding your use of the Web Site. Please read these Terms and Conditions carefully. By using any part of the Web Site, you agree to abide by these Terms and Conditions. If you do not wish to be bound by these Terms and Conditions, please do not use the Web Site.</p>
    
    <p>Skyvue Solutions reserves the right, at its discretion, to change these Terms and Conditions, including its Privacy Policy, and to change, suspend, or modify any aspect of the Web Site. Such modifications may include, without limitation, changes in content, user priorities and removal of functional aspects of the Web Site. It is your responsibility to check these Terms and Conditions, including the Privacy Policy, periodically for changes. Your continued use of the Web Site following the posting of changes will mean that you accept and agree to the changes.</p>
    
    <h4>OTHER TERMS AND CONDITIONS</h4>
    
    <p>Additional terms and conditions apply to purchases or licenses of other goods, services and software, and to specific portions or features of the Web Site, including the license and use of the web based software and related services (&ldquo;Web Application&rdquo;), all of which terms and conditions are made a part of these Terms and Conditions by this reference. You agree to abide by such terms and conditions. If there is conflict between these Terms and Conditions and the terms and conditions posted for or applicable to a specific portion of the Web Site or for any goods, services or software offered on or through the Web Site, the latter terms and conditions shall control with respect to your use of that portion of the Web Site or the specific good, service or software.</p>
    
    <h4>LIMITED LICENSE</h4>
    
    <p>Skyvue Solutions hereby grants you a limited license for accessing the Web Site, which limited license is expressly and strictly limited to those uses set forth in this provision. The limited license permits you to display on your computer, print, download and use the underlying HTML, text, audio clips, video clips, and other Content, as defined below, that is made available to you on the Web Site for your internal purposes, and not for commercial purposes, provided that the Content is not modified and includes and prominently displays the associated copyright and trademark notice, and this limited license, on each copy of such Content. The license is further expressly limited to only the Content on the Web Site that can be accessed without an authorized username and password. Use of a username and password not intentionally provided to you directly by Skyvue Solutions, or its affiliates, licensees or subsidiaries, however else obtained, is not authorized within the meaning of this Agreement and is prohibited. No other use is permitted. Except as outlined above, nothing in this license shall be interpreted so as to confer to you any right to any intellectual property held by Skyvue Solutions or any other person who owns intellectual property in the Content provided on Skyvue Solutions&rsquo;s Web Site.</p>
    
    <h4>COPYRIGHT AND TRADEMARK NOTICE</h4>
    
    <p>All text, photographs, video, audio, graphics, user interfaces, visual interfaces, trademarks, logos, artwork, presentations in any format, computer code and other content (collectively &ldquo;Content&rdquo;), including, but not limited to, the design, structure, selection, coordination, expression, &ldquo;look and feel&rdquo; and arrangement of such Content, is owned, controlled or licensed by or to Skyvue Solutions, and is protected by copyright, trademark and patent laws, and other intellectual property rights and unfair competition laws. Skyvue Solutions, and its associated logos, page headers, custom graphics, colors, buttons and other icons are trademarks and trade dress of Skyvue Solutions. Except as expressly provided in these Terms and Conditions, no part of this Web Site and no Content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way to any other computer, server, web site or other medium for publication or distribution or for any commercial enterprise, without Skyvue Solutions&rsquo;s prior written consent. Neither the Web Site or Content, nor any portion thereof, may be stored in a computer except as reasonably necessary for personal and non-commercial use; however, any print out of any page of the Web Site or portion thereof, must include Skyvue Solutions&rsquo;s copyright and trademark notices.</p>
    
    <h4>NO UNLAWFUL OR PROHIBITED USE</h4>
    
    <p>You may not use the Web Site or Content for any purpose that is unlawful or prohibited by these Terms and Conditions. You may not use the Web Site in any manner that could damage, disable, overburden, or impair any of Skyvue Solutions&rsquo;s servers or the network(s) connected to any of Skyvue Solutions&rsquo;s servers, or interfere with any other party&rsquo;s use and enjoyment of the Web Site or other products and services offered through the Web Site. You may not attempt to gain unauthorized access to any portion or feature of the Web Site, or any other accounts, computer systems or networks connected to the Web Site or any of Skyvue Solutions&rsquo;s servers or to any of the services or software offered by or through the Web Site, through hacking, password mining or any other means. You may not obtain or attempt to obtain through any means any materials or information not intentionally made available to you through the Web Site or other product or service provided.</p>
    
    <h4>LINKING</h4>
    
    <p>Without the prior written consent of Skyvue Solutions, you may not use any of Skyvue Solutions&rsquo;s proprietary logos, marks or other distinctive graphics, video or audio material or other Content in your links. You may not link to the Web Site or to any page thereof or engage in the practice of &ldquo;deep linking&rdquo; in any manner reasonably likely to: (a) imply affiliation with or endorsement or sponsorship of or by Skyvue Solutions; (b) cause confusion, mistake, or deception; (c) dilute Skyvue Solutions trademarks or service marks; (d) otherwise violate provincial or federal law; or (e) constitute improper derision or disclosure concerning Skyvue Solutions, and its officers, directors, members, managers, agents, or licensees. You may not frame or otherwise incorporate into another web site any of the Content or materials on the Web Site without the prior written consent of Skyvue Solutions.</p>
    
    <h4>YOUR RESPONSIBILITIES</h4>
    
    <p>You shall (a) be responsible for your compliance with these Terms and Conditions and all activities occurring within or from the Web Site, your construction project portfolio and your construction project, (b) be solely responsible for the accuracy, quality, integrity and legality of, and for the means by which you acquired any information or data posted on the Web Site, and your use of the Web Site (c) use commercially reasonable efforts to prevent unauthorized access to or use of the Web Site and other products and services available on the Web Site, and notify Skyvue Solutions promptly of any such unauthorized access or use, and (d) use the Web Site only in accordance with any user guide that Skyvue Solutions may promulgate from time to time and applicable laws and government regulations. You shall not (a) make the Web Site available to anyone else, (b) sell, resell, rent or lease the Web Site and the products and services available thereon, (c) use the Web Site to store or transmit infringing, libelous, or otherwise unlawful or tortious material, or to store or transmit material in violation of third-party privacy rights, (d) use the Web Site to store or transmit malicious code or malware, or to engage in phishing or other fraudulent activity, (e) interfere with or disrupt the integrity or performance of the Web Site third-party data and content contained therein, or (f) attempt to gain unauthorized access to the Web Site or the systems or networks of Skyvue Solutions.</p>
    
    
    <p>In addition, in using the Web Site you shall not:</p>
    <p>&ndash; impersonate any other person;</p>
    <p>&ndash; falsely say or imply that you are associated with another person or entity;</p>
    <p>&ndash; submit content in exchange for payment or other consideration from another person or entity, or otherwise use the Web Site in connection with unsolicited commercial messages;</p>
    <p>&ndash; violate any anti-spam or similar law;</p>
    <p>&ndash; collect information about other Web Site users without their express consent;</p>
    <p>&ndash; use or enable the use of any automated system that calls to the Web Site more frequently than may reasonably be performed by a human user using a standard web browser;</p>
    
    <p>Submit or link to any content that:</p>
    <p>&ndash; infringes or violates the intellectual property or other rights of any person or entity;</p>
    <p>&ndash; Intentionally interferes with the operation of the Web Site;</p>
    <p>&ndash; violates anyone&rsquo;s privacy or publicity rights;</p>
    <p>&ndash; breaches any duty of confidentiality that you owe to anyone;</p>
    <p>&ndash; provides any non-public information about Skyvue Solutions or any other company or person without authorization (including the names and contact information for other Web Site users);</p>
    <p>&ndash; is harassing, defamatory, abusive, lewd, pornographic, obscene or otherwise objectionable;</p>
    <p>&ndash; contains or installs any viruses, worms, bugs, Trojan horses, malware or other code, files or programs designed or having the capability to disrupt, damage or limit the functionality of any software or hardware; or contains false or deceptive language, unsubstantiated or comparative claims regarding Skyvue Solutions, our affiliates, licensees or others&rsquo; products, advertising, commercial referrals, or any other commercial solicitations.</p>
    
    <h4>TRACKING AND ANALYSIS</h4>
    <p>Skyvue Solutions may track and analyze your use of the Web Site and related products, services and software for the purpose of helping Skyvue Solutions improve both the Web Site and your experience in using the Web Site and services and software, including the Web Application. We may disclose such data about your use of the Web Site, services and software (&ldquo;Usage Statistics&rdquo;) to third parties for the purpose of assisting Skyvue Solutions in such tracking and analysis, or as required by law.</p>
    
    
    
    <h4>ACCESS AND DELAYS IN SERVICE</h4>
    
    <p>You acknowledge that Skyvue Solutions has made reasonable efforts to provide you with access to the Web Site. Further, Skyvue Solutions shall not be liable to you for any loss or liability resulting, directly or indirectly, from delays, inaccuracies, errors, omissions or interruptions of the Web Site for any reason, including, without limitation, electronic or mechanical equipment failures, connection problems, defects, failure of the World Wide Web, weather, strikes, walkouts, fire, acts of God, acts of war, or to other like causes. Skyvue Solutions reserves the right to interrupt the operation of the Web Site, or any portion thereof, as necessary to perform routine or non-routine maintenance, error correction or other changes and to modify or change the Web Site, or any portion thereof.</p>
    
    <h4>DISCLAIMER</h4>
    
    <p>SKYVUE SOLUTIONS DOES NOT PROMISE THAT THE WEB SITE OR ANY CONTENT OR FEATURE WILL BE ERROR FREE OR UNINTERRUPTED OR THAT ANY DEFECTS WILL BE CORRECTED. YOU ACKNOWLEDGE THAT THE WEB SITE AND CONTENT ARE PROVIDED TO YOU ON AN &ldquo;AS IS&rdquo;, &ldquo;WHEN AVAILABLE&rdquo; AND &ldquo;WITH ALL FAULTS&rdquo; BASIS. SKYVUE SOLUTIONS EXPRESSLY DISCLAIMS ANY AND ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS, WHETHER EXPRESS, ORAL, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, OR ANY WARRANTIES ARISING BY VIRTUE OF CUSTOM OF TRADE OR COURSE OF DEALING. FURTHER, SKYVUE SOLUTIONS NEITHER REPRESENTS NOR WARRANTS THAT THE WEB SITE AND CONTENT WILL MEET YOUR REQUIREMENTS OR IS SUITABLE FOR YOUR NEEDS OR WILL ACHIEVE ANY DESIRED RESULT.</p>
    
    <h4>VIRUSES</h4>
    
    <p>You acknowledge and agree that Skyvue Solutions has made reasonable efforts to ensure that no viruses or programs with similar functions to that of a virus operate on, or move through, the Web Site and Web Application. You hereby assume all responsibility (and thereby hold Skyvue Solutions harmless), by whatever means deemed most appropriate for your needs, for detecting and removing any virus or program with a similar function to that of a virus.</p>
    
    <h4>LIMITATION OF LIABILITY</h4>
    
    <p>EXCEPT WHERE PROHIBITED BY LAW, NEITHER SKYVUE SOLUTIONS NOR ANY OTHER THIRD PARTY INVOLVED IN CREATING, PRODUCING OR DELIVERING THE WEB SITE AND CONTENT SHALL BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, ARISING OUT OF THE USE OR INABILITY TO USE THE WEB SITE FOR ANY PURPOSE WHATSOEVER.</p>
    
    <h4>INDEMNIFICATION</h4>
    
    <p>You agree to defend, indemnify and hold Skyvue Solutions, its members, managers, shareholders, directors, employees, agents, representatives, predecessors, successors in interest, affiliates and licensees harmless from and against all claims, demands, loss, liability, costs and expenses, including attorneys&rsquo; fees, arising from, in connection with or relating to your use of the Web Site.</p>
    
    <h4>VIOLATION OF THESE TERMS AND CONDITIONS</h4>
    
    <p>We may disclose any information we have about you (including your identity) if we determine that such disclosure is necessary in connection with any investigation or complaint regarding your use of the Web Site, or to identify, contact or bring legal action against someone who may be causing injury to or interference with our rights or property or the rights or property of visitors or users of the Web Site. You agree that we may, in our sole discretion, terminate your access to the Web Site and block your future access if we determine you have violated these Terms and Conditions or other agreements which may be associated with your use of this Web Site, including the Web Application. If we do take legal action against you as a result of your violation of the Terms and Conditions or other agreements which may be associated with your use of this Web Site, we will be entitled to recover from you, and you agree to pay, all reasonable attorneys&rsquo; fees and costs incurred by us in connection with such action, in addition to any other relief granted to Skyvue Solutions.</p>
    
    <h4>GOVERNING LAW AND FORUM; LIMITATIONS</h4>
    
    <p>The laws of the United States, excluding any rule or principle that would refer to and apply the substantive law of another jurisdiction, shall govern these Terms and Conditions. To the extent allowed by applicable law, any claims or causes of action arising from or relating to your access and use of the Web Site as contemplated by these Terms and Conditions must be instituted within one (1) year from the date upon which such claim or cause arose, or such claim or cause is barred.</p>
    
    
    
    
    <h4>MISCELLANEOUS</h4>
    <h4>Waiver</h4>
    <p>A failure of Skyvue Solutions to exercise any right provided for herein shall not be deemed to be a waiver of such right.</p>
    
    <h4>Assignments</h4>
    <p>You may not assign any of your rights, obligations, privileges, or performance hereunder without the prior written consent of Skyvue Solutions. Any assignment other than as provided for in this Section shall be null and void.</p>
    
    <h4>Survival</h4>
    <p>If any provision of these Terms and Conditions is found to be unlawful or unenforceable in any respect, the court shall reform such provision to allow it to become enforceable. If the provision cannot be reformed so as to become enforceable, such a provision should be deleted from the agreement. After such changes are made, the court shall fully enforce these Terms and Conditions.</p>
    
    <h4>Termination</h4>
    <p>Skyvue Solutions may terminate or suspend your use of the Web Site for any reason. Termination or cancellation of your use of the Web Site shall not affect any right or relief to which Skyvue Solutions may be entitled, at law or in equity. Upon termination of this agreement, all rights granted to you will terminate and revert to Skyvue Solutions.</p>
    
    <h4>Entire Agreement</h4>
    <p>This agreement is complete and effective at the time you begin use of the Web Site. REGARDING USE OF THE WEB SITE, THESE TERMS AND CONDITIONS CONSTITUTE THE ENTIRE AGREEMENT BETWEEN THE PARTIES. In the event that any inconsistencies exist between these Terms and Conditions and any future published terms or understanding, the last published agreement or terms and conditions of use shall control. ANY RIGHTS NOT EXPRESSLY GRANTED HEREIN ARE RESERVED BY SKYVUE SOLUTIONS.</p>
    
    
</div>