import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { SharingService } from 'src/app/services/sharing.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/services/dashboard.service';
import {NgxEncryptCookieService} from 'ngx-encrypt-cookie';
import { environment } from 'src/environments/environment';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})

export class SidebarComponent implements OnInit {
  selectedRoute : boolean = false ;
  key = environment.key ;
  companyId: any = this.cookie.get("companyId",true,this.key);
  lastName: any = this.cookie.get("lastName",true,this.key);
  firstName: any = this.cookie.get("firstName",true,this.key);
  userDetails: any = {
    authorization: [],
    companies: [],
    createBy: "",
    dateCreate: "",
    email: "",
    firstName: "",
    isDelete: false,
    lastName: "",
    notifications: [],
    phone: "",
    profile: "",
    projects: [],
    role: "",
    type: "",
    _id: ""
  };
  
  selectedCompanies: any = {
    logo: "",
    name: "",
    storagePlan: 0,
    storageUsed: 0,
    users: []
  };
  menuList = [
    // {
    //   id: 0,
    //   title: 'Subscribe',
    //   value: 'subscribe',
    //   src: '/assets/images/premium.png',
    // },
    {
      id: 1,
      title: 'Dashboard',
      value: 'dashboard',
      src: '/assets/images/dashboard.svg',
    },
    {
      id: 2,
      title: 'Companies',
      value: 'companies',
      src: '/assets/images/office-building.svg',
    },
    {
      id: 5,
      title: 'Users',
      value: 'users',
      src: '/assets/images/department.svg',
    },
    // {
    //   id: 4,
    //   title: 'Notifications',
    //   value: 'notifications',
    //   src: '/assets/images/notification-direct.svg',
    // },
  ];
  hasSubscription: any = this.cookie.get("hasSubscription",true,this.key);
  constructor(private router: Router, private appService: SharingService,
    private helperService : HelperService, private cookie:NgxEncryptCookieService,private service: DashboardService, private toast: ToastrService) {

      let tempAssign = JSON.parse(localStorage.getItem('companyInfo'));
      if (!!tempAssign) {
        this.selectedCompanies = tempAssign;
      }
  }

  ngOnInit(): void {
    let userType = this.cookie.get("type",true,this.key);
    if (userType == 'client') {
      if(this.router.url.split("/", 2)[2] =="project"){
        // localStorage.setItem('currentPath',"projects");
        this.cookie.set("currentPath","projects",true,this.key,365,'/');
      }else if(this.router.url.split("/", 2)[2] =="employee"){
        // localStorage.setItem('currentPath',"users");
        this.cookie.set("currentPath","users",true,this.key,365,'/');
      }else{
        this.cookie.set("currentPath",this.router.url.split("/", 2)[1],true,this.key,365,'/');
      }
    }else{
      // localStorage.setItem('currentPath',this.router.url.split("/", 2)[1]);
      this.cookie.set("currentPath",this.router.url.split("/", 2)[1],true,this.key,365,'/');
    }
    let filter = this.menuList.find(
      (x) => x.value == this.cookie.get("currentPath",true,this.key)
    );
    if (filter) {
      filter['selected'] = true;
    } else {
        if(this.cookie.get("currentPath",true,this.key)!='projects'){
            this.menuList[0]['selected'] = true;
            this.gotoMenu(this.menuList[0]);
        }
    }
    this.getUserDetails();
    this.isProjectSelected();
  }
  navigateSettings() {
    this.appService.updateApprovalMessage(false);
    let userId = this.cookie.get("user_id",true,this.key);
    this.router.navigate([`/users/settings/${userId}`])
  }
  getUserDetails() {
    // let userId = this.cookie.get("user_id",true,this.key);
    // this.service.getUserDetailsByid(userId)
    //   .subscribe((response: any) => {
    //     if (response && user) {
      let user = JSON.parse(localStorage.getItem('userInfo'));
      this.userDetails = user;
      let companiesList = user.companies;
      if (this.helperService.isNullOrUndefined(this.cookie.get("companyId",true,this.key)) && this.helperService.isNullOrUndefined(localStorage.getItem('companyInfo'))) {
        let tempAssign = JSON.parse(localStorage.getItem('companyInfo'));
        this.selectedCompanies = tempAssign;
        // this.selectedCompanies = companiesList[0];
        this.cookie.set("companyId",this.selectedCompanies._id,true,this.key,365,'/');
      }else{
        let companyId = this.cookie.get("companyId",true,this.key);
        this.selectedCompanies = _.find(companiesList,x=>x._id == companyId);
      }
      //   }
      // })
  }
  onLogOut() {
    this.appService.updateApprovalMessage(false);
    Swal.fire({
      text : "Are you sure want to sign out?",
      showCancelButton : true,
      icon:"warning"
    }).then((result)=>{
      if (result.isConfirmed) {
        this.toast.success('Sign out successfully');
        this.cookie.deleteAll("");
        localStorage.clear();
        setTimeout(() => {
          this.router.navigate(['/signin']);
        }, 2000);
      }
    })
  }
  gotoMenu(menu) {
    this.showMenu();

    let companyId = this.cookie.get("companyId",true,this.key);
    let userType = this.cookie.get("type",true,this.key);

    if (menu.value=="companies" && userType == 'client') {
      // localStorage.setItem('currentPath', "projects");
      this.cookie.set("currentPath","projects",true,this.key,365,'/');
    }else{
      // localStorage.setItem('currentPath', menu.value);
      this.cookie.set("currentPath",menu.value,true,this.key,365,'/');
    }

    switch (menu.value) {
      case 'dashboard':
        return this.router.navigate(['/dashboard']);
      case 'companies':
        return this.router.navigate(['/companies']);

      case 'employees':
        return this.router.navigate(['/employees']);

      case 'notifications':
        return this.router.navigate(['/notifications']);
      case 'users':
        if (userType == 'client') {
          return this.router.navigate(['/companies/employee/' + companyId]);
        }else{
          return this.router.navigate(['/users']);
        }
      case 'projects':
        return this.router.navigate(['/projects']);
      case 'subscribe':
        return this.router.navigate(['/subscribe']);
    }
  }
  subscribe(){
    let userType = this.cookie.get("type",true,this.key);
      // localStorage.setItem('currentPath', 'subscribe');
      this.cookie.set("currentPath","subscribe",true,this.key,365,'/');
    return this.router.navigate(['/subscribe']);
  }
  getClass(menu) {
    if (menu.selected) {
      return 'active';
    } else {
      return '';
    }
  }

  onRedirectEmployee() {
    let companyId = this.cookie.get("companyId",true,this.key);
    this.router.navigate(['/companies/employee/' + companyId]);
  }
  dynamicMenu(menu) {
    let userType = this.cookie.get("type",true,this.key);
    if (userType == 'client' && menu.id == 2) {
      if(this.selectedCompanies.type=="Telecommunication"){
        (menu.title = 'Towers'), (menu.value = 'projects');
      }else{
        (menu.title = 'Projects'), (menu.value = 'projects');
      }
      if (this.cookie.get("currentPath",true,this.key)=='projects') {
        menu.selected = true;
      }
      else{
        menu.selected = false;

      }
    }
    if(userType == 'admin' && this.userDetails.role == "collaborator" && menu.id!=1){
      return false;
    }
    return true;
  }

  showMenu() {
    this.appService.updateApprovalMessage(false);
  }
  gotoDashboard(){
    let companyId = this.cookie.get("companyId",true,this.key);
    let projectId = this.cookie.get("projectId",true,this.key);
    this.router.navigate([`/companies/project/${companyId}/details`],{queryParams:{projectId:projectId}})
  }
  isProjectSelected(){
    let route = this.router.url.split("/")[1]+'/'+this.router.url.split("/")[2];
    let userType = this.cookie.get("type",true,this.key);
    if(route=="companies/project" && userType == 'client' ){
      this.selectedRoute = true;
    // }else if(route=="companies/project" && this.router.url.split("/")[4] && userType == 'admin' ){
    //   this.selectedRoute = true;
    }else{
      this.selectedRoute =  false;
    }
  }
}
