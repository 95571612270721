import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { LoaderService } from './loader.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root',
})

export class CoreHttpService {

  constructor(private http: HttpClient, private loaderService: LoaderService) { }

  httpGetRequest<TResponse>(
    url: string,
    reqHeader?: HttpHeaders,
    showLoader?: boolean
  ): Observable<TResponse> {
    showLoader = showLoader === undefined ? true : showLoader;
    this.loaderService.display(showLoader);
    return this.http.get(url, { headers: reqHeader }).pipe(
      map((res) => {
        this.loaderService.display(false);
        return res as TResponse;
      }),
      catchError(this.errorHandler)
    );
  }

  httpPostRequest<TRequest, TResponse>(
    url: string,
    data: TRequest,
    reqHeader?: HttpHeaders,
    showLoader?: boolean
  ): Observable<TResponse> {
    showLoader = showLoader === undefined ? true : showLoader;
    this.loaderService.display(showLoader);
    return this.http.post(url, data, { headers: reqHeader }).pipe(
      map((res) => {
        this.loaderService.display(false);
        return res as TResponse;
      }),
      catchError(this.errorHandler)
    );
  }

  httpDeleteRequest<TRequest, TResponse>(
    url: string,
    id?: TRequest,
    showLoader?: boolean
  ): Observable<TResponse> {
    showLoader = showLoader === undefined ? true : showLoader;
    this.loaderService.display(showLoader);
    return this.http.delete(url, id).pipe(
      map((res) => {
        this.loaderService.display(false);
        return res as TResponse;
      }),
      catchError(this.errorHandler)
    );
  }

  httpPutRequest<TRequest, TResponse>(
    url: string,
    data: TRequest,
    reqHeader?: HttpHeaders,
    showLoader?: boolean
  ): Observable<TResponse> {
    showLoader = showLoader === undefined ? true : showLoader;
    this.loaderService.display(showLoader);
    return this.http.put(url, data, { headers: reqHeader }).pipe(
      map((res) => {
        this.loaderService.display(false);
        return res as TResponse;
      }),
      catchError(this.errorHandler)
    );
  }

  errorHandler = (error: HttpErrorResponse) => {
    this.loaderService.display(false);
    if (error?.error?.duplicate) {
     
      Swal.fire({
        icon: 'error',
        title: 'Duplicate record found in system.',
        showConfirmButton: false,
        timer: 2000
      }).then((result) => {
      });;
    }
    else {
      if (error.error instanceof ErrorEvent) {
        console.error('An error occurred:', error.error.message);
      } else {
        console.error(
          `Backend returned code ${error.status},` + `body was: ${error.error}`
        );
      }
      return Observable.throw(error);
    }

  };
}
