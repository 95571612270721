import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../components/header/header.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { UiImageLoaderDirective } from './image-directive';
import { LoaderComponent } from './loader/loader.component';
import { OnlyNumberDirective } from './directive/only-number.directive';
import { FilterPipe } from './pipe/filter.pipe';
import { PresentationComponent } from './presentation/presentation.component';
import { TimeagoModule } from 'ngx-timeago';
import { ReloaderComponent } from './reloader/reloader.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    UiImageLoaderDirective,
    LoaderComponent,
    ReloaderComponent,
    OnlyNumberDirective,
    FilterPipe,
    PresentationComponent,
  ],
  imports: [
    CommonModule,
    TimeagoModule
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    UiImageLoaderDirective,
    LoaderComponent,
    ReloaderComponent,
    OnlyNumberDirective,
    FilterPipe,
    TimeagoModule

  ]
})
export class SharedModule { }
