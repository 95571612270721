import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import {NgxEncryptCookieService} from 'ngx-encrypt-cookie';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth.guard';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TermsAndConditionComponent } from './components/terms-privacy-cookie/terms-and-condition/terms-and-condition.component';
import { PrivacyPolicyComponent } from './components/terms-privacy-cookie/privacy-policy/privacy-policy.component';
import { CookieComponent } from './components/terms-privacy-cookie/cookie/cookie.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { TimeagoModule } from 'ngx-timeago';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
@NgModule({
  declarations: [
    AppComponent,
    TermsAndConditionComponent,
    PrivacyPolicyComponent,
    CookieComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, // <----- import for date formating(optional)
    GuidedTourModule,
    ToastrModule.forRoot(),
    TimeagoModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    NgxChartsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.measurementId),
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    GuidedTourService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    NgxEncryptCookieService,
    
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
