import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from 'src/app/services/dashboard.service';
import { HelperService } from 'src/app/services/helper.service';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import { SharingService } from 'src/app/services/sharing.service';
import { ToastrService } from 'ngx-toastr';
import { NgxEncryptCookieService } from 'ngx-encrypt-cookie';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  firstName = '';
  lastName = '';
  userType = '';
  profile = '';
  subscription_type = '';
  companyDetails: any;
  badge: any = [];
  companiesList: any = [];
  selectedCompanies: any = {
    logo: "",
    name: "",
    storagePlan: 0,
    storageUsed: 0,
    users: []
  };
  showMenu: Boolean = false;
  userInfo: any = {
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    profile: "",
    notifications: [],
    projects: []
  };
  key = environment.key;
  @Output() companiesListToEmit = new EventEmitter<any>();
  @Output() userDetails = new EventEmitter<any>();
  @Output() tutorial = new EventEmitter<any>();

  constructor(
    private router: Router,
    private toast: ToastrService,
    private service: DashboardService,
    private helperService: HelperService,
    private cookie: NgxEncryptCookieService,
    private appService: SharingService,
  ) {
    let tempAssign = JSON.parse(localStorage.getItem('companyInfo'));
    let role = this.cookie.get("type", true, this.key);

    if (
      (!!tempAssign &&
        role == 'admin' &&
        window.location.pathname.includes('/companies/project/')) ||
      window.location.pathname.includes('/companies/employee/')
    ) {
      this.companyDetails = tempAssign;
      this.selectedCompanies = this.companyDetails;
      this.userType;
    }
  }

  ngOnInit(): void {
    this.getUserInfo();

    this.appService.currentApprovalStageMessage.subscribe((msg: any) => this.showMenu = msg)
  }

  getUserInfo() {
    this.firstName = this.cookie.get("firstName", true, this.key);
    this.lastName = this.cookie.get("lastName", true, this.key);
    this.userType = this.cookie.get("type", true, this.key);
    this.profile = this.cookie.get("profile", true, this.key);
    this.subscription_type = this.cookie.get("subscription_type", true, this.key);
    // if (this.userType == 'client') {
    let userId = this.cookie.get("user_id", true, this.key);
    if (!!userId) {
      this.getUserInfoDetails(userId);
    }
    // }
  }
  onLogOut() {
    Swal.fire({
      text: "Are you sure want to sign out?",
      showCancelButton: true,
      icon: "warning"
    }).then((result) => {
      if (result.isConfirmed) {
        this.toast.success('Sign out successfully');
        this.cookie.delete('companyId');
        this.cookie.delete('subscription_type');
        this.cookie.delete('isSubscriptionExpired');
        this.cookie.delete('projectName');
        this.cookie.delete('hasSubscription');
        this.cookie.delete('profile');
        this.cookie.delete('firstName');
        this.cookie.delete('user_id');
        this.cookie.delete('currentPath');
        this.cookie.delete('lastName');
        this.cookie.delete('projectId');
        this.cookie.delete('type');
        this.cookie.deleteAll();
        this.cookie.deleteAll("/");
        localStorage.clear();
        setTimeout(() => {
          this.router.navigate(['/signin']);
        }, 2000);
      }
    })
  }
  readAllNotification() {
    this.service.readAllNotifications(this.userInfo._id).subscribe(
      (data: any) => {
        if (data.success) {
          this.userInfo.notifications = data.data.notifications
        }
      });
  }

  getUserInfoDetails(userId) {
    // this.service.getUserDetailsByid(userId).subscribe(
    //   (data: any) => {
    //     if (data.success) {
          let user = JSON.parse(localStorage.getItem('userInfo'));
          if ((!!user && user.companies.length > 0 && user.type == "client") || (!!user && user.type == "admin")) {
            this.userInfo = {
              _id: user._id,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              profile: user.profile,
              notifications: user.notifications,
              type: user.type,
              role: user.role,
              companies: user.companies,
              projects: user.projects,
            }
            if (this.userType == 'client') {
              this.badge = user.notifications.filter(e => e.seen === false);
              this.companiesList = user.companies;
              if (this.helperService.isNullOrUndefined(this.cookie.get("companyId", true, this.key))) {
                this.selectedCompanies = this.companiesList[0];
                // localStorage.setItem('companyId',this.selectedCompanies._id);
                this.cookie.set("companyId", this.selectedCompanies._id, true, this.key,365,'/');
              } else {
                let companyId = this.cookie.get("companyId", true, this.key);
                this.selectedCompanies = _.find(this.companiesList, x => x._id == companyId);
              }
            }
            this.emitValue(this.selectedCompanies, this.userInfo);
          }
        // }
    //   },
    //   (error) => {
    //     // this.loaderStatus = false;
    //   }
    // );
  }

  showBlue() {
    if (this.showMenu) this.showMenu = false;
    else this.showMenu = true;
    this.appService.updateApprovalMessage(this.showMenu);
  }

  routeDashboard() {
    // localStorage.setItem("currentPath","dashboard");
    this.cookie.set("currentPath", "dashboard", true, this.key,365,'/');
    this.router.navigate(['/dashboard']);
  }
  onChange(company) {
    this.selectedCompanies = company;
    // localStorage.setItem('companyId',this.selectedCompanies._id);
    localStorage.setItem('companyInfo', JSON.stringify(company));
    this.cookie.set("companyId", this.selectedCompanies._id, true, this.key,365,'/');
    if (this.cookie.get("currentPath", true, this.key) == 'projects' || this.cookie.get("currentPath", true, this.key) == 'dashboard') {
      this.emitValue(this.selectedCompanies, this.userInfo);
    } else {
      this.router.navigate(['/projects']);
    }
  }
  emitValue(company, userinfo) {
    this.companiesListToEmit.emit(company);
    this.userDetails.emit(userinfo);
  }

  emitTuto() {
    this.tutorial.emit();
  }
  navigateSettings() {
    let userId = this.cookie.get("user_id", true, this.key);
    this.router.navigate([`/users/settings/${userId}`])
  }
}
