<div class="main-sidebar ">
    <span class="sidebar-cls" (click)="showMenu()">
        <img src="/assets/images/close-icon.png" alt="Close" />
    </span>
    <ul>
        <li>
            <div class="dropdown profile-dropdown forMobile">
                <button class="btn btn-secondary dropdown-toggle col-12" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="pfl-thumb"><img src="/assets/images/avatar.png" alt="" /></span>
                    <div class="pfl-name col-7">
                        <h6 class="text-capital text-white overflow-hidden">{{firstName}}</h6>
                        <span class="text-capital overflow-hidden">{{lastName}}</span>
                    </div>
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" (click)="navigateSettings()">Settings</a>
                    <a class="dropdown-item" (click)="onLogOut()">Logout</a>
                </div>
            </div>
        </li>
        <!-- <li style="background-color:#ec8b23!important" *ngIf="hasSubscription==0">
            <a (click)="subscribe()" style="color:white;" ><i><img src="/assets/images/premium.png" width="24" alt="premium"/></i>
                Subscribe </a>
        </li> -->
        <!-- <li *ngFor="let menu of menuList"
        [attr.style]="menu.value == 'subscribe' ? (hasSubscription==0 ? 'background-color:#ec8b23!important;':'background-color:#ec8b23!important;display:none!important') : ''">
            <a (click)="gotoMenu(menu)" [attr.style]="menu.value == 'subscribe' ? 'color:white' : ''" [ngClass]="getClass(menu)" *ngIf="dynamicMenu(menu)"><i>
                <img src={{menu.src}} alt={{menu.title}} [attr.width]="menu.value == 'subscribe' ? '24' : ''" /></i>
                {{menu.title}}</a>
        </li> -->
        <li *ngFor="let menu of menuList"
        [attr.style]="menu.value == 'subscribe' ? 'background-color:#ec8b23!important;' : ''">
            <a (click)="gotoMenu(menu)" [attr.style]="menu.value == 'subscribe' ? 'color:white' : ''" [ngClass]="getClass(menu)" *ngIf="dynamicMenu(menu)"><i>
                <img src={{menu.src}} alt={{menu.title}} [attr.width]="menu.value == 'subscribe' ? '24' : ''" /></i>
                {{menu.title}}</a>
        </li>
        
        <li style="background-color:#ec8b23!important;" *ngIf="userDetails.type == 'client' && selectedRoute">
            <a (click)="gotoDashboard()" style="color:white" ><i>
                <img src="/assets/images/dashboard.svg" style="filter: brightness(10);" alt="project details"/></i>
                Project dashboard</a>
        </li>
    </ul>
</div>

<!-- Mobile Sidebar -->
