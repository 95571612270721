<nav class="navbar navbar-expand-md navbar-dark fixed-top">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-sm-4 col-xs-12 hdr-lt">
                <a class="navbar-brand" style="cursor: pointer;">
                    <img src="/assets/images/access logo.png" (click)="routeDashboard()" alt="Logo">
                </a>
            </div>

            <div class="col-sm-4 col-xs-12 text-center select-clt">
                <div class="btn-group" *ngIf="userType=='client' && userInfo.id!==selectedCompanies?.name">
                    <div *ngIf="companiesList.length == 1">
                        <!-- <img class="cmp-img" src={{selectedCompanies?.logo}}> -->
                        <img onerror="this.src='/assets/images/avatar-1.svg'" class="cmp-img"
                            [src]="selectedCompanies?.logo">
                        <span class="text-select"> {{selectedCompanies?.name}}</span>
                    </div>
                    <button type="button" class="btn btn-default dropdown-toggle comp-drop"
                        [(ngModel)]="selectedCompanies" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" *ngIf="companiesList.length > 1">
                        <!-- <img class="cmp-img" src={{selectedCompanies?.logo}}> -->
                        <img onerror="this.src='/assets/images/avatar-1.svg'" class="cmp-img"
                            [src]="selectedCompanies?.logo">
                        <span class="text-select"> {{selectedCompanies?.name}}</span>
                        <span class="glyphicon glyphicon-chevron-down"></span>
                    </button>

                    <ul class="dropdown-menu dropdown-menu-right" *ngIf="companiesList.length > 1"
                        style="height: 250px;overflow: auto;">
                        <li *ngFor="let company of companiesList" (click)="onChange(company)">
                            <a title="company">
                                <!-- <img src={{company.logo}} class="cmp-img">{{company.name}} -->
                                <img onerror="this.src='/assets/images/avatar-1.svg'" class="cmp-img"
                                    [src]="company.logo">{{company.name}}
                            </a>
                        </li>
                        <!-- <li>
                        <a title="Select this card"><img src="http://lorempixel.com/75/50/abstract/">0123 4567 8912 34561</a>
                    </li>
                    <li>
                        <a title="Select this card"><img src="http://lorempixel.com/75/50/abstract/">0123 4567 8912 34562</a>
                    </li> -->
                    </ul>
                </div>
            </div>

            <div
                [ngClass]="userType != 'client'  ? 'col-sm-4 col-xs-12 justify-content-end d-flex hdr-rt' : 'col-sm-4 col-xs-12 justify-content-end d-flex hdr-rt'">
                <div class="hdr-rt-act">
                    <div>
                        <a href="https://wa.me/654014952" target="_blank"> <span class="support">Support &nbsp;</span>
                            <img src="assets/images/whatsapp1.png" class="whatsapp" alt="">
                        </a>
                    </div>
                    <span class="hdr-noti ml-2">
                        <div class="dropdown notification">
                            <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <img src="/assets/images/notification.svg" alt="Notification" />
                            </button>
                            <span class="badge" *ngIf="badge.length > 0">{{badge.length}}</span>
                            <div class="dropdown-menu cmn-shadow-box comp-dash-blk chart-blk"
                                aria-labelledby="dropdownMenuButton">
                                <div class="notif-header">
                                    <h3>Notifications</h3>
                                    <img src="/assets/images/cross.svg" class="cancalnotif">
                                </div>
                                <div class="rcnt-act-list">
                                    <div *ngIf="userInfo.notifications.length == 0" class="p-2 text-center text-muted">
                                        <p>No notifications.</p>
                                    </div>
                                    <ul *ngIf="userInfo.notifications.length > 0">
                                        <li *ngFor="let value of userInfo.notifications">
                                            <div
                                                class="rcnt-act-thumb d-flex justify-content-center align-items-center">
                                                <span class="thumb-tag blue-tag" *ngIf="!value.seen"></span>
                                            </div>
                                            <div class="rcnt-act-rt">
                                                <p> {{value.notification.message}}</p>
                                                <small class="activity-date">{{value.notification.date |
                                                    timeago:live}}</small>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="notif-footer" (click)="readAllNotification()">
                                    <a>View All</a>
                                </div>
                            </div>
                        </div>
                    </span>
                    <div class="dropdown profile-dropdown forDesktop">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="pfl-thumb">
                                <img src="/assets/images/avatar.png" alt="" *ngIf="!profile" />
                                <img src="{{profile}}" alt="" *ngIf="profile" />
                            </span>
                            <div class="pfl-name">
                                <h6 class="text-capital">{{firstName}} &nbsp;{{lastName}}</h6>
                                <span class="text-capital">{{userType}}</span>
                            </div>
                            <i class="dropdown-arrow"><img src="/assets/images/chevron-down.svg"
                                    alt="Arrow Dropdown"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item d-flex justify-content-between" (click)="emitTuto()">
                                Page tour
                                <img src="/assets/images/tutorial.svg" alt="Tutorial" width="12" /></a>
                            <a class="dropdown-item" (click)="navigateSettings()">Settings</a>
                            <a class="dropdown-item" (click)="onLogOut()">Logout</a>
                        </div>
                    </div>
                    <span class="hamburger" (click)="showBlue()"><img src="/assets/images/hamburger-icon.png"
                            alt="Hamburger Menu"></span>
                </div>
            </div>
        </div>
    </div>
    <div class="comp-info-hdr" *ngIf="!!companyDetails">
        <span class="comp-info">
            <img *ngIf="companyDetails.logo" src={{companyDetails.logo}} alt="Logo">
            <img *ngIf="!companyDetails.logo" src="/assets/images/avatar-1.svg" alt="Logo">

        </span>
        {{companyDetails.name}}
    </div>
</nav>