import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CoreHttpService } from './core-http.service';
import {NgxEncryptCookieService} from 'ngx-encrypt-cookie';
@Injectable({
  providedIn: 'root',
})

export class DashboardService {
  public apiBase = environment.apiBase;
  key = environment.key ;
  token = localStorage.getItem('token');
  constructor(private coreHttp: CoreHttpService,private cookie:NgxEncryptCookieService, private http: HttpClient) { }

  getUserDetailsByid(userID) {
    var headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'authtoken': this.token
    });
    return this.coreHttp.httpPostRequest(this.apiBase + 'user/find?', { idUser: userID }, headers_object);
  }
  readAllNotifications(userID) {
    var headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
      'authtoken': this.token
    });
    return this.coreHttp.httpGetRequest(
      this.apiBase + `notification/readAll/${userID}?`,
      headers_object
    );
  }


}
