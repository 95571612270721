import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {JwtHelperService} from '@auth0/angular-jwt';
import {NgxEncryptCookieService} from 'ngx-encrypt-cookie';

@Injectable({
  providedIn: 'root',
  // providers: [JwtHelperService],
})

export class AuthService {
  public apiBase = environment.apiBase;
  key = environment.key ;

  constructor(
    private http: HttpClient,
    private cookie:NgxEncryptCookieService,
    private jwtHelper: JwtHelperService
    ) { }

  doLogin(username: string, password: string, persist_token: boolean) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    // let options = new RequestOptions({ headers: headers, withCredentials: true });
    return this.http.post(this.apiBase + 'user/login', {
      email: username,
      password: password,
      persist_token: persist_token
    });
  }

  doSignup(signupForm: any) {
    return this.http.post(this.apiBase + 'user/register', signupForm);
  }

  forgetPassword(emailaddress: string) {
    return this.http.post(this.apiBase + 'user/initrecoverpassword', {
      email: emailaddress,
    });
  }

  resetPassword(password: string) {
  }

  isLoggedIn() {
    if (localStorage.getItem('token') && !this.jwtHelper.isTokenExpired(localStorage.getItem('token')) && 
    this.cookie.get("user_id",true,this.key)) {
      return true;
    }
    return false;
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('companyInfo');
    localStorage.removeItem('user_id');
    this.cookie.delete('user_id');
    this.cookie.deleteAll("/");
  }
}
