import { Component, OnInit } from '@angular/core';
import { LoaderService } from './services/loader.service';
import { SwUpdate } from '@angular/service-worker';
import { fromEvent } from 'rxjs';
import Swal from 'sweetalert2';
import {
    Router,
    Event as RouterEvent,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    NavigationError
  } from '@angular/router'
import { SharingService } from './services/sharing.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {
  title = 'skyvue';
  showLoader = false;
  showMenu: Boolean = false;
  update: boolean = false;

  constructor(private loaderService: LoaderService,private router: Router, private appService: SharingService, updates: SwUpdate) {

    this.router.events.subscribe((e : RouterEvent) => {
        this.navigationInterceptor(e);
      })

      updates.available.subscribe(event=>{
        // this.update = true;
        updates.activateUpdate().then( ()=> document.location.reload() );
      })

      this.handleNetwork();
      
  }
  ngOnInit() {
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });
    this.appService.currentApprovalStageMessage.subscribe((msg: any) => this.showMenu = msg);
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showLoader = true
    }
    if (event instanceof NavigationEnd) {
      this.showLoader = false
    }

    if (event instanceof NavigationCancel) {
      this.showLoader = false
    }
    if (event instanceof NavigationError) {
      this.showLoader = false
    }
  }

  handleNetwork() {
    fromEvent(window, 'offline').subscribe(event => {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'You are offline',
        showConfirmButton: false,
        timer: 2500
      });
    });
    fromEvent(window, 'online').subscribe(event => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'You are back online',
        showConfirmButton: false,
        timer: 2500
      });
      location.reload();
    });
  }
  
}
