import {
  Directive,
  Attribute,
  Renderer2,
  ElementRef,
  HostListener,
  Input
} from '@angular/core';
@Directive({
  selector: '[ImageLoader]'
})
export class UiImageLoaderDirective {
  @Input() imagePath;
  constructor(
    @Attribute('loader') public loader: string,
    @Attribute('onErrorSrc') public onErrorSrc: string,
    private renderer: Renderer2,
    private el: ElementRef) {
    this.renderer.setAttribute(this.el.nativeElement, 'src', '/assets/images/loading-buffering.gif');
  }

  @HostListener('load') onLoad() {
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.el.nativeElement.src);
  }
  @HostListener('error') onError() {
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.onErrorSrc);
  }
}