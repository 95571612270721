import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharingService {

  constructor() { }

  private approvalStageMessage = new BehaviorSubject(false);
  currentApprovalStageMessage = this.approvalStageMessage.asObservable();
 
  updateApprovalMessage(message: any) {
  this.approvalStageMessage.next(message)
  }
}
