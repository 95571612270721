import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, Route } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { NgxEncryptCookieService } from 'ngx-encrypt-cookie';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  public apiBase = environment.apiBase;
  key = environment.key;
  constructor(private authService: AuthService, private cookie: NgxEncryptCookieService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    let url = route.path;

    return this.checkLogin(url);
  }


  private checkLogin(url: string) {
    if (this.authService.isLoggedIn()) {
      if(url == '/signin' || url == '/signup' || url == '/forgotpassword' ){
        this.router.navigate(['/dashboard']);
      }
      return true;
    } else {
      this.cookie.deleteAll("");
      localStorage.clear();
      setTimeout(() => {
        this.router.navigate(['/signin/'], { queryParams: { attemptedUrl: url } });
        // this.router.navigate(['/signin']);
      }, 2000);
      return false;
    }
  }
}
