import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-reloader',
  templateUrl: './reloader.component.html',
  styleUrls: ['./reloader.component.scss']
})
export class ReloaderComponent implements OnInit {
  @Input() loading;
  constructor() {

   }

  ngOnInit(): void {
  }
}
