import {  Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild   } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, animate, transition, useAnimation } from '@angular/animations';

import { bounceIn, bounceOut } from '../animations/bounce.animation';
import { SlideRight } from '../animations/slide.animation';
import {  Fade } from '../animations/fade.animation';
@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss'],
  animations: [
    SlideRight,
    Fade,
    trigger('bounce', [
      transition(
        'void => *',
        useAnimation(bounceIn, {
          params: { timing: 0.4 },
        })
      ),
      transition(
        '* => void',
        useAnimation(bounceOut, {
          params: { timing: 0.6 },
        })
      ),
    ]),
  ]
})
export class PresentationComponent implements OnInit {

  slide: any = 1;
  @Output() emitService = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    ) { }

  ngOnInit(): void {
  }

  onCancel() {
    this.modalService.dismissAll();
    this.emitService.next('cancel');
  }
  skip() {
    this.modalService.dismissAll();
  }
  next() {
    if(this.slide == 1){
      this.slide = 0
      setTimeout(() => {
        this.slide= 2
      }, 610);
    }

    if(this.slide == 2){
      this.slide = 0
      setTimeout(() => {
        this.slide= 3
      }, 610);
    }
  }
  previous() {

    if(this.slide == 2){
      this.slide = 0
      setTimeout(() => {
        this.slide= 1
      }, 610);
    }

    if(this.slide == 3){
      this.slide = 0
      setTimeout(() => {
        this.slide= 2
      }, 610);
    }
    // this.slide--;
  }
}
