
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  measurementId: 'G-81Y2ZM3XPQ',
  key:"3c1a9cf909c10c2ff4866709710dc50c",
  // apiBase: 'https://localhost:27085/api/'
  apiBase: 'https://backend.skyvuesolutions.com:27085/api/',
};

// export const environment = {
//   production: false,
//   measurementId: 'G-81Y2ZM3XPQ',
//   apiBase: 'https://192.168.100.251:27085/api/'
//   // apiBase: 'https://backend.skyvuesolutions.com:27085/api/'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
